var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from 'react';
import PropTypes from 'prop-types';
import APQRCode from 'apex-web/lib/components/common/APQRCode/APQRCode';
import APIcon from 'apex-web/lib/components/common/APIcon';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import copyToClipboard from 'apex-web/lib/helpers/clipboardHelper';
import destinationTagHelper from 'apex-web/lib/helpers/destinationTagHelper';
import './ReceiveAddress.css';

var bemClasses = getBEMClasses('receive-address');

var ReceiveAddressComponent = function (_React$Component) {


  _inherits(ReceiveAddressComponent, _React$Component);

  function ReceiveAddressComponent() {
    _classCallCheck(this, ReceiveAddressComponent);

    return _possibleConstructorReturn(this, (ReceiveAddressComponent.__proto__ || Object.getPrototypeOf(ReceiveAddressComponent)).apply(this, arguments));
  }

  _createClass(ReceiveAddressComponent, [{
    key: 'componentWillMount',
    value: function componentWillMount() {
      var _props = this.props,
        selectDepositProduct = _props.selectDepositProduct,
        product = _props.product;

      var Account_provider_id = this.props.account_provider_ID;

      if (12 !== product.ProductId) {
        selectDepositProduct(product.ProductId, Account_provider_id);
      }

    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      // console.log(this.props)

      var _props2 = this.props,
        addressList = _props2.deposit.depositInfo,
        _props2$deposit = _props2.deposit,
        isLoading = _props2$deposit.isLoading,
        error = _props2$deposit.error,
        product = _props2.product,
        showSnack = _props2.showSnack,
        WalletsDisabledMessage = _props2.WalletsDisabledMessage,
        NetworkAddress = _props2.NetworkAddress;

      // Protocol prefix for the QR Code, lowercase name with no spaces.

      var depositType = product.ProductFullName.toLowerCase().replace(/\W/g, '');
      var selectedAddress = void 0;

      if (!isLoading && addressList.length) {
        selectedAddress = addressList[addressList.length - 1];
      }

      // this is a static tmp BEP20 address, for campaign use only (all user share same address)
      if ('BEP20_tmp' == NetworkAddress) {
        selectedAddress = '0xfde6A621B18Df742F5BFB8cC3531d08d5063AdaA'
      } else if ('TRC20_tmp' == NetworkAddress) {
        selectedAddress = 'TD2S6pSgmcUGpbifUzRCtyUuTQwrBr3QNP'
      }

      var _destinationTagHelper = destinationTagHelper(selectedAddress),
        _destinationTagHelper2 = _slicedToArray(_destinationTagHelper, 2),
        address = _destinationTagHelper2[0],
        destinationTag = _destinationTagHelper2[1];

      // This is hard-coded for Product which have both ERC-20 and BEP-20 enabled at the same time.
      // Display a option for user to choose between ERC and BEP.
      // You need to add the Product ID and account provider ID mannuly in the code, when there is a new product need this function.
      // Requested by Philip.
      return (
        React.createElement(
          'section',
          { className: bemClasses() },
          selectedAddress && !error && React.createElement(
            React.Fragment,
            null,
            React.createElement(
              'p',
              { className: bemClasses('description'), style: { fontSize: "2rem" } },
              this.context.t('Scan this QR code to receive funds:')
            ),
            React.createElement(
              'div',
              { className: bemClasses('qr-code') },
              React.createElement(APQRCode, { value: '' + selectedAddress })
            ),
            React.createElement(
              'p',
              { className: bemClasses('copy-address-text') },
              this.context.t('Or copy this address:')
            ),
            React.createElement(
              'div',
              {
                className: bemClasses('copy-address-payload'),
                onClick: function onClick() {
                  showSnack({
                    id: 'RECEIVE_ADDRESS_COPY',
                    text: _this2.context.t('The address has been copied to the clipboard.')
                  });
                  copyToClipboard(address);
                }
              },
              React.createElement(
                'span',
                { className: bemClasses('address') },
                address
              ),
              React.createElement(APIcon, { name: 'copy', customClass: bemClasses('copy-icon') })
            ),
            destinationTag && React.createElement(
              'div',
              {
                onClick: function onClick() {
                  showSnack({
                    id: 'RECEIVE_ADDRESS_COPY',
                    text: _this2.context.t('The destination tag has been copied to the clipboard.')
                  });
                  copyToClipboard(destinationTag);
                }
              },
              React.createElement(
                'p',
                { className: bemClasses('destination-tag-text') },
                this.context.t('Be sure to include this destination tag:')
              ),
              React.createElement(
                'span',
                { className: bemClasses('address') },
                destinationTag
              ),
              React.createElement(APIcon, { name: 'copy', customClass: bemClasses('copy-icon') })
            )
          ),
          isLoading && React.createElement(Spinner, {
            text: this.context.t('Loading url...'),
            customClass: bemClasses
          }),
          WalletsDisabledMessage ? React.createElement(
            'span',
            { className: bemClasses('error') },
            this.context.t(WalletsDisabledMessage)
          )
            :
            // Tmp sol. , don't show error , since this may not a real error. (means that a account provider return null is still a error , but it is not)
            // error && React.createElement(
            //   'span',
            //   { className: bemClasses('error') },
            //   this.context.t(error)
            // )
            ""
        )
      )


    }
  }]);

  return ReceiveAddressComponent;
}(React.Component);

ReceiveAddressComponent.defaultProps = {
  deposit: {
    templateInfo: {},
    template: {},
    isLoading: false,
    error: '',
    depositInfo: {},
    depositStatus: {}
  },
  product: {},
  account_provider_id: null
};

ReceiveAddressComponent.propTypes = {
  product: PropTypes.object,
  account_provider_id: PropTypes.number,
  deposit: PropTypes.shape({
    templateInfo: PropTypes.object.isRequired,
    template: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    product: PropTypes.number,
    depositInfo: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    depositStatus: PropTypes.shape({
      success: PropTypes.bool,
      RequestCode: PropTypes.string
    }).isRequired
  })
};

ReceiveAddressComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ReceiveAddressComponent;