import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import APIcon from '../components/common/APIcon';
import redirectIfAuthorized from 'apex-web/lib/hocs/redirectIfAuthorized';
import path from '../helpers/path';
import { getBEMClasses } from '../helpers/cssClassesHelper';
import StandAloneLayout from '../layouts/StandAloneLayout/StandAloneLayout';
// import Big_X_Logo from "../images/custom/Big_X_logo.svg";
import Big_X_Logo from '../images/custom/Big_X_logo_new.png';

import SignupFormContainer from '../components/SimpleSignupForm';

// import '../styles/components/common/StandaloneForm.css';
// import './SignupPage.css';
import styled from 'styled-components';

const baseClasses = getBEMClasses('standalone-form');
const signupPageClasses = getBEMClasses('signup-page');

const Bg = styled.div`
  min-height: 120vh;
  background: white;
`;

const LoginSection = styled.section`
  min-height: calc(100vh - 129px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
`;

export const SignupFormComponent = (props, context) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const campaign_url_param = urlParams.get('campaign');
  console.log(campaign_url_param);

  return (
    <React.Fragment>
      {/* <Bg> */}
      <div>
        <div className="X_object_login">
          <img src={Big_X_Logo} />
        </div>
        <div className="header__mobile__action d-xl-none">
          <div className="mobile__header ">
            <span className="rightsidebar btn btn-text">
              <i className="bi bi-filter-left" />
            </span>
            <span className="leftsidebar btn btn-text">
              <i className="bi bi-filter-right" />
            </span>
          </div>
        </div>
        <header>
          <div className="login__screen__btns">
            {campaign_url_param == null ? (
              <Link
                to="/login"
                className="theme-btn withoutgradient me-0 with-border">
                {context.t('Log In')}
              </Link>
            ) : (
              ''
            )}
            <Link to="/signup" className="theme-btn">
              {context.t('Sign Up')}
            </Link>
          </div>
        </header>
        <LoginSection>
          <div className="login__screen__content">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 text_on_X_object">
                  <h1>{context.t('Create your GreenX account')}</h1>
                  <h2 className="loginSto_news">
                    {context.t(
                      'Welcome to GreenX! The Pre-Registration period starts NOW.You will be notified shortly when it is ready to Deposit,Withdraw and Trading.'
                    )}
                  </h2>
                </div>
                <div className="col-lg-6">
                  <div className="login__form__main">
                    <SignupFormContainer />
                    {/* <form>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Valid Email Address"
                          id="username"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Password"
                          id="password"
                        />
                        <div id="password" className="form-text">
                          Contains at least 8 characters, 1 number, 1 capital
                          letter
                        </div>
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Retype Password"
                          id="retypepassword"
                        />
                      </div>
                      <button type="submit" className="theme-btn">
                        Sign Up
                      </button>
                    </form> */}
                    <div className="cant__login">
                      <ul>
                        <li>
                          <Link to="/login">
                            {context.t('Already have an account? Sign in')}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="signup__underformtext">
                    <p>
                      {context.t(
                        'By Clicking Sign up you accept our Terms and Conditions and Privacy Policy'
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LoginSection>
      </div>
      {/* </Bg> */}
    </React.Fragment>
  );
};

SignupFormComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default redirectIfAuthorized(
  SignupFormComponent,
  process.env.REACT_APP_DEFAULT_PATH
);
