import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import withAuthentication from 'apex-web/lib/hocs/withAuthentication';
import { getBEMClasses } from '../helpers/cssClassesHelper';
import './PurchaseFormPage.css';
import PageFooterComponent from 'apex-web/lib/components/PageFooter/PageFooterComponent';
import { connect } from 'react-redux';
import {
  getUserConfig,
  getUserInfo
} from 'apex-web/lib/redux/actions/userActions';

const layoutClasses = getBEMClasses('purchase-form-page');

const PurchaseFormPage = (props, context) => {
  var source = {
    url: 'https://www.green-x.io/forms/gxt-presale.html',
    title: 'GXT Presale',
    fullUrl: ''
  };
  const [showForm, setShowForm] = useState(true);
  const sessionEndTime = 'August 30, 2024 23:59:00';
  const targetDate = new Date(sessionEndTime);
  React.useEffect(() => {
    props.getUserInfo();
    props.getUserConfig();
    const currentDate = new Date();

    if (currentDate > targetDate) {
      setShowForm(false);
    }
  }, []);

  // USDT Wallet Balance
  var balanceUSDT = 0;
  if (props.wallet_balance) {
    balanceUSDT = props.wallet_balance['12'].Amount;
  }

  const urlParams = new URLSearchParams(window.location.search);

  // set form url and append params
  let ref_source = urlParams.get('ref');
  let user_info = props.user.userInfo;
  source.fullUrl =
    source.url +
    '?username=' +
    user_info.UserName +
    '&email=' +
    user_info.Email +
    '&language=' +
    localStorage.getItem('language') +
    '&code=GX_PRESALE_AUG' +
    '&ref=' +
    ref_source +
    '&session_end_time=' +
    sessionEndTime +
    '&balanceUSDT=' +
    balanceUSDT;

  return (
    <React.Fragment>
      <div className={layoutClasses('accent-bar')} />
      {showForm ? (
        <iframe
          className="purchase-form-iframe"
          src={source.fullUrl}
          title={source.title}
          allow="clipboard-read; clipboard-write"
        />
      ) : (
        <div className="full-height">
          <h3>The flash sale has ended.</h3>
        </div>
      )}

      <div className={layoutClasses('footer')}>
        <PageFooterComponent />
      </div>
    </React.Fragment>
  );
};

PurchaseFormPage.contextTypes = {
  t: PropTypes.func.isRequired
};

var mapStateToProps = function mapStateToProps(state) {
  return {
    user: state.user,
    wallet_balance:
      state.apexCore.position.positions[state.user.selectedAccountId]
  };
};

var mapDispatchToProps = {
  getUserInfo,
  getUserConfig
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(PurchaseFormPage));
