var _createClass = (function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
      typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import VerificationRequiredContainer from 'apex-web/lib/components/VerificationRequired/VerificationRequiredContainer';
import { product } from 'apex-web/lib/propTypes/sendReceiveComponent';
import ReceiveFormContainer from '../ReceiveForm/ReceiveFormContainer';
import ReceiveAddressContainer from '../ReceiveAddress/ReceiveAddressContainer';
import ReceiveAddressKlaytnContainer from '../ReceiveAddressKlaytn/ReceiveAddressContainer';
import ERC20_icon from '../img/ERC20_icon.png';
import BEP20_icon from '../img/BEP20_icon.png';
import TRC20_icon from '../img/tron.png';
import './Receive.css';

var bemClasses = getBEMClasses('receive');
var bemClasses_receive_address = getBEMClasses('receive-address');

var ReceiveComponent = (function (_React$Component) {
  _inherits(ReceiveComponent, _React$Component);

  function ReceiveComponent() {
    _classCallCheck(this, ReceiveComponent);

    return _possibleConstructorReturn(
      this,
      (
        ReceiveComponent.__proto__ || Object.getPrototypeOf(ReceiveComponent)
      ).apply(this, arguments)
    );
  }

  _createClass(ReceiveComponent, [
    {
      key: 'render',
      value: function render() {
        // console.log(this.props)
        var _props = this.props,
          product = _props.product,
          useExternalAddress = _props.useExternalAddress,
          toggleUseExternalAddress = _props.toggleUseExternalAddress,
          onChange = _props.onChange,
          onSubmit = _props.onSubmit,
          disableDeposit = _props.disableDeposit,
          verificationOnClick = _props.verificationOnClick,
          allowTransfer = _props.allowTransfer,
          // for switching network
          fetch_Product_Deposit_Template =
            _props.fetch_Product_Deposit_Template,
          toggleNetworkAddress = _props.toggleNetworkAddress,
          NetworkAddress = _props.NetworkAddress,
          current_deposit_address = _props.deposit.depositInfo[0];

        var Product_ID = product.ProductId;

        // for switching network , key is product ID , value is account provider ID. Add it when you need more.
        var ERC20_account_provider_list = { '13': 34, '2': 47, '12': 16 };
        // same as above
        // Product ID 12 = USDT
        var BEP20_account_provider_list = { '13': 46, '12': 24 };
        // account provider id
        let setAccProviderID =
          NetworkAddress == 'BEP20'
            ? BEP20_account_provider_list[product.ProductId]
            : ERC20_account_provider_list[product.ProductId];
        var productInfo = { ...product, account_provider_ID: setAccProviderID };

        return React.createElement(
          'section',
          { className: bemClasses() },
          React.createElement(
            'header',
            { className: bemClasses('source') },
            allowTransfer &&
            React.createElement(
              'div',
              { className: bemClasses('source-item-with-border') },
              React.createElement(
                'div',
                {
                  className: bemClasses('source-item', {
                    active: !useExternalAddress
                  }),
                  onClick: function onClick() {
                    useExternalAddress && toggleUseExternalAddress();
                  }
                },
                this.context.t('Friend')
              )
            ),
            React.createElement(
              'div',
              {
                className: bemClasses('source-item', {
                  active: useExternalAddress
                }),
                onClick: function onClick() {
                  !useExternalAddress && toggleUseExternalAddress();
                }
              },
              this.context.t('External Wallet')
            )
          ),
          React.createElement(
            'section',
            { className: bemClasses('body') },
            disableDeposit
              ? React.createElement(VerificationRequiredContainer, {
                disabled: disableDeposit,
                onClick: verificationOnClick
              })
              : useExternalAddress
                ? // Klaytn
                Product_ID == 5
                  ? React.createElement(ReceiveAddressKlaytnContainer, {
                    product: product
                  })
                  : // This is hard-coded for Product which have both ERC-20 and BEP-20 enabled at the same time.
                  // Display a option for user to choose between ERC and BEP.
                  // You need to add the Product ID and account provider ID mannuly in the code, when there is a new product need this function.
                  // Requested by Philip.
                  Product_ID == 13 || Product_ID == 12 || Product_ID == 2
                    ? React.createElement(
                      'section',
                      { style: { paddingTop: '0rem' } },
                      React.createElement(
                        React.Fragment,
                        null,
                        React.createElement(
                          'div',
                          {
                            style: {
                              display: 'flex',
                              justifyContent: 'space-evenly',
                              flexDirection: 'column',
                              marginBottom: '2.5rem'
                            }
                          },
                          // React.createElement(
                          //   'div',
                          //   {
                          //     className: 'unavailable-note'
                          //   },
                          //   this.context.t(
                          //     'BEP20 is currently unavailable and will be back in service soon.'
                          //   )
                          // ),
                          React.createElement(
                            'div',
                            {
                              style: {
                                display: 'flex',
                                justifyContent: 'space-evenly',
                                flexDirection: 'row'
                              }
                            },
                            React.createElement(
                              'p',
                              { style: { fontSize: '2rem' } },
                              this.context.t('Choose Your Wallet Network')
                            )
                          ),
                          React.createElement(
                            'div',
                            {
                              style: {
                                display: 'flex',
                                justifyContent: 'space-evenly',
                                flexDirection: 'row'
                              }
                            },
                            // ERC20 box
                            React.createElement(
                              'div',
                              {
                                style: {
                                  display: 'flex',
                                  justifyContent: 'space-evenly',
                                  flexDirection: 'column',
                                  padding: '10px',
                                  borderRadius: '20px'
                                },
                                className:
                                  NetworkAddress == 'ERC20'
                                    ? 'network_button_selected'
                                    : 'network_button',
                                onClick: function onClick() {
                                  toggleNetworkAddress('ERC20');
                                  fetch_Product_Deposit_Template(
                                    Product_ID,
                                    ERC20_account_provider_list[Product_ID]
                                  );
                                }
                              },
                              React.createElement(
                                'div',
                                {
                                  style: {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center'
                                  }
                                },
                                React.createElement(
                                  'img',
                                  {
                                    src: ERC20_icon,
                                    style: { width: '10rem' }
                                  },
                                  null
                                )
                              ),
                              React.createElement(
                                'div',
                                {
                                  style: {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    marginTop: '1.5rem',
                                    fontSize: '2rem'
                                  }
                                },
                                'ERC-20'
                              )
                            ),
                            // box end

                            // BEP20 box
                            Product_ID == 12
                              ?
                              React.createElement(
                                'div',
                                {
                                  style: {
                                    display: 'flex',
                                    justifyContent: 'space-evenly',
                                    flexDirection: 'column',
                                    padding: '10px',
                                    borderRadius: '20px'
                                  },
                                  className:
                                    NetworkAddress == 'BEP20'
                                      ? 'network_button_selected'
                                      : 'network_button',
                                  onClick: function onClick() {
                                    toggleNetworkAddress('BEP20');
                                    fetch_Product_Deposit_Template(
                                      Product_ID,
                                      BEP20_account_provider_list[Product_ID]
                                    );
                                  }
                                },
                                React.createElement(
                                  'div',
                                  {
                                    style: {
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'center'
                                    }
                                  },
                                  React.createElement(
                                    'img',
                                    {
                                      src: BEP20_icon,
                                      style: { width: '10rem' }
                                    },
                                    null
                                  )
                                ),
                                React.createElement(
                                  'div',
                                  {
                                    style: {
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'center',
                                      marginTop: '1.5rem',
                                      fontSize: '2rem'
                                    }
                                  },
                                  'BEP-20'
                                )
                              ) : "",
                            // box end

                            // tamp BEP-20 box (only have 1 static address, for campaign use) (USDT only)
                            // Product_ID == 12
                            //   ? React.createElement(
                            //       'div',
                            //       {
                            //         style: {
                            //           display: 'flex',
                            //           justifyContent: 'space-evenly',
                            //           flexDirection: 'column',
                            //           padding: '10px',
                            //           borderRadius: '20px'
                            //         },
                            //         className:
                            //           NetworkAddress == 'BEP20_tmp'
                            //             ? 'network_button_selected'
                            //             : 'network_button',
                            //         onClick: function onClick() {
                            //           toggleNetworkAddress('BEP20');
                            //         }
                            //       },
                            //       React.createElement(
                            //         'div',
                            //         {
                            //           style: {
                            //             display: 'flex',
                            //             flexDirection: 'row',
                            //             justifyContent: 'center'
                            //           }
                            //         },
                            //         React.createElement(
                            //           'img',
                            //           {
                            //             src: BEP20_icon,
                            //             style: { width: '10rem' }
                            //           },
                            //           null
                            //         )
                            //       ),
                            //       React.createElement(
                            //         'div',
                            //         {
                            //           style: {
                            //             display: 'flex',
                            //             flexDirection: 'row',
                            //             justifyContent: 'center',
                            //             marginTop: '1.5rem',
                            //             fontSize: '2rem'
                            //           }
                            //         },
                            //         'BEP-20'
                            //       )
                            //     )
                            //   : '',
                            // box end

                            // tamp TRC-20 box (only have 1 static address, for campaign use) (USDT only)
                            Product_ID == 12
                              ? React.createElement(
                                'div',
                                {
                                  style: {
                                    display: 'flex',
                                    justifyContent: 'space-evenly',
                                    flexDirection: 'column',
                                    padding: '10px',
                                    borderRadius: '20px'
                                  },
                                  className:
                                    NetworkAddress == 'TRC20_tmp'
                                      ? 'network_button_selected'
                                      : 'network_button',
                                  onClick: function onClick() {
                                    toggleNetworkAddress('TRC20_tmp');
                                  }
                                },
                                React.createElement(
                                  'div',
                                  {
                                    style: {
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'center'
                                    }
                                  },
                                  React.createElement(
                                    'img',
                                    {
                                      src: TRC20_icon,
                                      style: { width: '10rem' }
                                    },
                                    null
                                  )
                                ),
                                React.createElement(
                                  'div',
                                  {
                                    style: {
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'center',
                                      marginTop: '1.5rem',
                                      fontSize: '2rem'
                                    }
                                  },
                                  'TRC-20'
                                )
                              )
                              : ''
                            // box end
                          )
                        )
                      ),
                      NetworkAddress == 'ERC20' ||
                        NetworkAddress == 'BEP20' ||
                        NetworkAddress == 'BEP20_tmp' ||
                        NetworkAddress == 'TRC20_tmp'
                        ? React.createElement(ReceiveAddressContainer, {
                          product: productInfo,
                          NetworkAddress: NetworkAddress
                        })
                        : ''
                    )
                    : // end
                    React.createElement(ReceiveAddressContainer, {
                      product: product
                    })
                : React.createElement(ReceiveFormContainer, {
                  product: product,
                  onChange: onChange,
                  onSubmit: onSubmit
                })
          )
        );
      }
    }
  ]);

  return ReceiveComponent;
})(React.Component);

ReceiveComponent.propTypes = {
  product: product,
  useExternalAddress: PropTypes.bool.isRequired,
  toggleUseExternalAddress: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  allowTransfer: PropTypes.bool.isRequired,
  VerificationRequiredComponent: PropTypes.element,
  disableDeposit: PropTypes.bool,
  verificationOnClick: PropTypes.func
};

ReceiveComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ReceiveComponent;
