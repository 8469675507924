var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }
    return arr2;
  } else {
    return Array.from(arr);
  }
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

import React from 'react';
import PropTypes from 'prop-types';
import APSelect from 'apex-web/lib/components/common/APSelect';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { templateFormRenderer } from 'apex-web/lib/helpers/formTemplateHelper';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import config from 'apex-web/lib/config';

import './SendAddress.css';

var baseClasses = getBEMClasses('send-address');

var SendAddressComponent = (function(_React$Component) {
  _inherits(SendAddressComponent, _React$Component);

  function SendAddressComponent() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, SendAddressComponent);

    for (
      var _len = arguments.length, args = Array(_len), _key = 0;
      _key < _len;
      _key++
    ) {
      args[_key] = arguments[_key];
    }

    return (
      (_ret = ((_temp = ((_this = _possibleConstructorReturn(
        this,
        (_ref =
          SendAddressComponent.__proto__ ||
          Object.getPrototypeOf(SendAddressComponent)).call.apply(
          _ref,
          [this].concat(args)
        )
      )),
      _this)),
      (_this.getProductData = function() {
        var _this$props = _this.props,
          selectWithdrawProduct = _this$props.selectWithdrawProduct,
          ProductId = _this$props.product.ProductId;

        selectWithdrawProduct(ProductId);
      }),
      _temp)),
      _possibleConstructorReturn(_this, _ret)
    );
  }

  _createClass(SendAddressComponent, [
    {
      key: 'componentWillMount',
      value: function componentWillMount() {
        this.getProductData();
      }
    },
    {
      key: 'render',
      value: function render() {
        var _props = this.props,
          withdrawInfo = _props.withdrawInfo,
          template = _props.withdrawInfo.template,
          product = _props.product,
          ProductId = _props.product.ProductId,
          selectWithdrawTemplate = _props.selectWithdrawTemplate,
          WalletsDisabledMessage = _props.WalletsDisabledMessage,
          verificationLevelConfig = _props.verificationLevelConfig;

        var showTemplateFields = Object.keys(template || {}).length;
        var showTemplateTypeSelect =
          withdrawInfo.templateTypes.length > 1 &&
          !Object.keys(withdrawInfo.templateInfo).length &&
          !withdrawInfo.isLoading;
        var template_label_str = {
          ToExternalBitcoinAddress: 'BNB Smart Chain (BEP-20)',
          ToExternalBinanceSmartChainAddress: 'BEP-20',
          ToExternalEthereumAddress: 'Ethereum (ERC-20)'
        };
        var templateTypes = [{ value: '', label: 'Select an option' }].concat(
          _toConsumableArray(
            withdrawInfo.templateTypes.map(function(template) {
              console.log(
                'AccountProviderDisplayKey:',
                template[config.Withdraw.AccountProviderDisplayKey]
              );
              return {
                value: template.AccountProviderId,
                label:
                  template_label_str[
                    template[config.Withdraw.AccountProviderDisplayKey]
                  ]
              };
            })
          )
        );

        // Disable BEP20 from FE -------------- START
        var disableBEP20Products = {
          '35': 41, // DIGau
          '12': 38, // USDT
          '19': 42, // 2UT
          '2': 37 // ETH
        };
        const shouldProductDisabled = ProductId in disableBEP20Products;
        var templateTypes = templateTypes.filter(
          option => option.value !== disableBEP20Products[ProductId]
        );
        // Disable BEP20 from FE -------------- END

        var hasError = withdrawInfo.error || WalletsDisabledMessage;

        return React.createElement(
          'div',
          null,
          withdrawInfo.withdrawStatus.success
            ? React.createElement(
                'div',
                { className: baseClasses('success') },
                this.context.t('Your withdraw ticket was created successfully.')
              )
            : React.createElement(
                'div',
                { className: baseClasses('form-body') },
                hasError
                  ? React.createElement(
                      'div',
                      { className: baseClasses('error') },
                      hasError
                    )
                  : React.createElement(
                      React.Fragment,
                      null,
                      withdrawInfo.isLoading &&
                        React.createElement(Spinner, null),
                      showTemplateTypeSelect &&
                        React.createElement(
                          React.Fragment,
                          null,
                          shouldProductDisabled &&
                            ProductId != 12 &&
                            React.createElement(
                              'div',
                              {
                                className: 'unavailable-note'
                              },
                              this.context.t(
                                'BEP20 is currently unavailable and will be back in service soon.'
                              )
                            ),
                          React.createElement(
                            'p',
                            null,
                            this.context.t(
                              'Select an option to continue the withdraw process'
                            )
                          ),
                          React.createElement(APSelect, {
                            name: 'TemplateType',
                            customClass: baseClasses(),
                            label: this.context.t('Options for withdraw'),
                            onSelect: function onSelect(value) {
                              return selectWithdrawTemplate(ProductId, value);
                            },
                            options: templateTypes
                          })
                        ),
                      showTemplateFields > 0 &&
                        React.createElement(
                          'div',
                          { className: baseClasses('section') },
                          templateFormRenderer(
                            template,
                            baseClasses(),
                            this.context,
                            product,
                            verificationLevelConfig
                          )
                        )
                    )
              )
        );
      }
    }
  ]);

  return SendAddressComponent;
})(React.Component);

SendAddressComponent.defaultProps = {
  withdrawInfo: {
    templateInfo: {},
    template: {},
    isLoading: false,
    error: '',
    TemplateForm: {},
    withdrawStatus: {},
    getWithdrawFee: function getWithdrawFee() {}
  }
};

SendAddressComponent.propTypes = {
  getWithdrawFee: PropTypes.func,
  selectWithdrawProduct: PropTypes.func.isRequired,
  product: PropTypes.shape({
    ProductSymbol: PropTypes.string,
    ProductId: PropTypes.number
  }).isRequired,
  withdrawInfo: PropTypes.shape({
    templateInfo: PropTypes.object.isRequired,
    template: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    TemplateForm: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    withdrawStatus: PropTypes.shape({
      success: PropTypes.bool,
      RequestCode: PropTypes.string
    }).isRequired
  })
};

SendAddressComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default SendAddressComponent;
